exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-darbi-tsx": () => import("./../../../src/pages/darbi.tsx" /* webpackChunkName: "component---src-pages-darbi-tsx" */),
  "component---src-pages-identitate-tsx": () => import("./../../../src/pages/identitate.tsx" /* webpackChunkName: "component---src-pages-identitate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-komanda-tsx": () => import("./../../../src/pages/komanda.tsx" /* webpackChunkName: "component---src-pages-komanda-tsx" */),
  "component---src-pages-kontakti-tsx": () => import("./../../../src/pages/kontakti.tsx" /* webpackChunkName: "component---src-pages-kontakti-tsx" */),
  "component---src-pages-reklama-tsx": () => import("./../../../src/pages/reklama.tsx" /* webpackChunkName: "component---src-pages-reklama-tsx" */),
  "component---src-pages-strategija-tsx": () => import("./../../../src/pages/strategija.tsx" /* webpackChunkName: "component---src-pages-strategija-tsx" */),
  "component---src-pages-tendences-tsx": () => import("./../../../src/pages/tendences.tsx" /* webpackChunkName: "component---src-pages-tendences-tsx" */),
  "component---src-templates-darbs-tsx": () => import("./../../../src/templates/darbs.tsx" /* webpackChunkName: "component---src-templates-darbs-tsx" */),
  "component---src-templates-trend-tsx": () => import("./../../../src/templates/trend.tsx" /* webpackChunkName: "component---src-templates-trend-tsx" */)
}

